export const ToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const fileToJSON = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(JSON.parse(event.target.result));
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
};

export const ToURLBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
    reader.onerror = (error) => reject(error);
  });
};

export const CheckLimitImgSize = (files) => {
  // Check if any file is selected.
  if (files.length > 0) {
    for (let i = 0; i <= files.length - 1; i++) {
      let fsize = files.item(i).size;
      let file = Math.round(fsize / 1024);
      // The size of the file.
      if (file >= 5120) {
        return "ra.info.maxSizeUpload";
      } else {
        return false;
      }
    }
  }
};

export const CheckLimitImgAssetSize = (files) => {
  // Check if any file is selected.
  if (files.length > 0) {
    for (let i = 0; i <= files.length - 1; i++) {
      let fsize = files.item(i).size;
      let file = Math.round(fsize / 1024);
      // The size of the file.
      if (file >= 1024) {
        return "ra.info.maxImageSizeUpload";
      } else {
        return false;
      }
    }
  }
};

export default { ToBase64, fileToJSON, ToURLBase64, CheckLimitImgSize,CheckLimitImgAssetSize };
