import React, { useState, useContext } from "react";
import { GblContext } from "providers/formatter";
import { useTranslate } from "react-admin";
//import clsx from "clsx";
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Drawer,
  Typography,
  Tooltip,
  Divider,
  Icon,
  Input,
  IconButton,
  TextField,
} from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import NumberFormatInput from "components/NumberFormatInput";
import DimensionContent from "components/DimensionContent";
import TaxVatContent from "components/TaxVatContent";
import SummaryRightSide from "components/SummaryRightSide";
import DialogWorkflowHis from "components/DialogWorkflowHis";
import {
  addComment,
  deleteComment,
  getCommentById,
  downloadCommentById,
  getCommentListByModule,
} from "services/comment";
import { getProcessLogList } from "services/setting";
import { withRouter } from "react-router";
import { format } from "date-fns";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";
import DialogViewInvoice from "components/DialogViewPaymentAP";
import DeleteIcon from "@material-ui/icons/Delete";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0),
    marginTop: -16,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  divIcon: {
    padding: theme.spacing(0, 0.5),
  },
  avtCenter: {
    margin: "0 auto",
    padding: theme.spacing(0.6),
    cursor: "pointer",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
  },
  paper: {
    padding: "6px 16px",
  },
  content: {
    padding: 5,
  },
  contentRight: {
    textAlign: "right",
    marginRight: 10,
  },
}));

const TaxOpen = ({ dataTax, module }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { DateToString } = useContext(GblContext);
  return (
    <Box>
      <Box>
        <Typography variant="h6" className={classes.content}>
          {translate("ra.message.details")}{" "}
        </Typography>
        {module === "AR_Invoice" ? (
          <>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Comment")}
                variant="outlined"
                margin="dense"
                disabled
                //inputProps={{ style: { textAlign: "right" } }}
                value={dataTax?.InvdDesc}
                multiline
                fullWidth
              />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField label={"Group"} variant="outlined" margin="dense" disabled fullWidth value={dataTax.GroupNo} />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Date")}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
                value={DateToString(dataTax.InvdDate)}
              />
            </Box>
          </>
        ) : (
          <>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Comment")}
                variant="outlined"
                margin="dense"
                disabled
                //inputProps={{ style: { textAlign: "right" } }}
                value={dataTax?.InvdDesc}
                multiline
                fullWidth
              />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Dr Dept. Code")}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
                value={dataTax.DeptCode ? `${dataTax.DeptCode} : ${dataTax.DeptDesc}` : ""}
              />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Dr Acc. Code")}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
                value={`${dataTax.DrAcc} ${dataTax.DrAccDesc ? `: ${dataTax.DrAccDesc}` : ""}`}
              />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Cr Dept. Code")}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
                value={dataTax.CrDept ? `${dataTax.CrDept} : ${dataTax.CrDeptDesc}` : ""}
              />
            </Box>
            <Box mx={0.5} p={0.5}>
              <TextField
                label={translate("ra.field.Cr Acc. Code")}
                variant="outlined"
                margin="dense"
                disabled
                fullWidth
                value={`${dataTax.CrAcc} ${dataTax.CrAccDesc ? `: ${dataTax.CrAccDesc}` : ""}`}
              />
            </Box>
          </>
        )}
      </Box>

      <TaxVatContent data={dataTax} module={module} />
      <Divider />
      <Typography variant="h6" className={classes.content}>
        {translate("ra.field.Base Summary")}
      </Typography>
      <SummaryRightSide data={dataTax} />
    </Box>
  );
};

const CommentOpen = (props) => {
  const translate = useTranslate();
  const { module, moduleId } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const userName = localStorage.getItem("UserName");
  const [dataComment, setDataComment] = useState();
  const [message, setMessage] = useState();

  async function getCommentList(module, moduleId) {
    const response = await getCommentListByModule(module, moduleId);
    if (response) {
      response.Data.forEach((element) => {
        element.stringDate = format(new Date(element.LastModified), "dd/MM/yyyy (HH:mm)");
        if (element.Message === null) {
          element.Message = "Void";
        }
      });
      setDataComment(response.Data);
      setIsLoading(false);
    }
  }
  React.useEffect(() => {
    if (props.location.pathname.search("create") !== -1) {
      return;
    }
    if (!dataComment) {
      let newModule = module;
      if (newModule === "AR-Receipt") {
        newModule = "AR_RECEIPT";
      }
      getCommentList(newModule, moduleId);
    }
  }, [dataComment, module, moduleId, props.location.pathname]);

  const attachFile = (e) => {
    if (e.target.files.length > 0) {
      const msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("FileInfo").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        const param = {
          Module: module,
          ModuleId: moduleId,
          Message: "",
          File: base64String,
          FileInfo: {
            FileName: e.target.files[0].name,
            FileExtension: e.target.files[0].type,
          },
          UserModified: userName,
        };
        await addComment(param);
        await getCommentList(module, moduleId);
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const sendComment = async (e) => {
    if (message) {
      const param = {
        Module: module,
        ModuleId: moduleId,
        Message: message,
        UserModified: userName,
      };
      await addComment(param);
      await getCommentList(module, moduleId);
      setMessage("");
    }
  };

  const deleteItem = async (id) => {
    await deleteComment(id);
    await getCommentList(module, moduleId);
    setMessage("");
  };

  const doResizeOrSendComment = async (e) => {
    const key = e.key;
    if (key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        if (message !== "") {
          setIsLoading(true);
          const param = {
            Module: module,
            ModuleId: moduleId,
            Message: message,
            UserModified: userName,
          };
          await addComment(param);
          await getCommentList(module, moduleId);
          setMessage("");
        }
      }
    } else {
      const maxrows = 5;
      const txt = e.target.value;
      const cols = e.target.cols;

      const arraytxt = txt.split("\n");
      let rows = arraytxt.length;

      for (let i = 0; i < arraytxt.length; i++) rows += parseInt(arraytxt[i].length / cols);

      if (rows > maxrows) e.target.rows = maxrows;
      else e.target.rows = rows;
    }
  };

  const Downloadlink = ({ item }) => {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const clickFile = async (id, file) => {
      if (file.FileExtension.search("image") !== -1) {
        //view image
        const r = await getCommentById(id);
        if (r.File) {
          const blob = b64toBlob(r.File, item.FileInfo.FileExtension);
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank").focus();
        }
      } else {
        //view downloadfile
        const blob = await downloadCommentById(id);
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, file.FileName);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = file.FileName;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        }
      }
    };

    return (
      <Tooltip title={"download"}>
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => clickFile(item.Id, item.FileInfo)}
        >
          {item.FileInfo?.FileName}
        </div>
      </Tooltip>
    );
  };

  if (props.location.pathname.search("create") !== -1) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" className={classes.content}>
        {translate("ra.field.Comment")}
      </Typography>
      <div className={classes.content}>
        {dataComment?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={
                item.UserModified === userName ? "chatbox__messageBox text-right" : "chatbox__messageBox text-left"
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: item.UserModified === userName ? "flex-end" : "flex-start",
                }}
              >
                {item.UserModified}
                <Icon>person</Icon>
                {item.stringDate}
              </div>
              <div className={item.UserModified === userName ? "message-box currentUser" : "message-box"}>
                {item.Message && item.Message !== "" && item.Message !== null ? (
                  <>{item.Message}</>
                ) : (
                  <Downloadlink item={item} />
                )}
              </div>
              {item.UserModified === userName && (
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    const msg = translate("ra.question.confirmDel");
                    SnackbarUtils.delConfirm(msg, async function () {
                      await deleteItem(item.Id);
                    });
                  }}
                  style={{ padding: 0 }}
                >
                  <DeleteIcon style={{ width: 16 }} />
                </IconButton>
              )}
            </div>
          );
        })}
        <div className="chatbox-popup__footer">
          <label htmlFor="FileInfo">
            <Input
              id="FileInfo"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                attachFile(e);
              }}
            />
            <IconButton aria-label="upload picture" component="span" style={{ padding: ".5rem" }}>
              <Icon>attach_file</Icon>
            </IconButton>
          </label>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <textarea
              className="textAreaComment"
              type="text"
              placeholder="Type your message here..."
              value={message}
              onKeyUp={(e) => doResizeOrSendComment(e)}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              rows="1"
            />
          )}
          <IconButton onClick={(e) => sendComment(e)} style={{ padding: ".5rem" }}>
            <Icon>send</Icon>
          </IconButton>
        </div>
      </div>
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(dataComment, 0, 2) : ""}</pre>
    </Box>
  );
};

const ActivityOpen = (props) => {
  const translate = useTranslate();
  const { module, moduleId, DateTimeToString } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [activityLogData, setActivityLogData] = useState();

  const theme = createTheme({
    overrides: {
      MuiTypography: {
        body1: {
          fontSize: "0.7777777777777778rem !important",
        },
        h6: {
          fontSize: "1rem !important",
        },
      },
      MuiTimelineContent: {
        root: {
          fontSize: "0.7777777777777778rem !important",
          padding: "6px 16px",
          textAlign: "left !important",
        },
      },
    },
  });

  async function getActivityLog(module, moduleId) {
    let uriQueryString = {
      Limit: 0,
      Page: 1,
      OrderBy: { LogDate: "desc" },
      WhereGroupList: [
        {
          AndOr: "And",
          ConditionList: [
            {
              AndOr: "And",
              Field: "Module",
              Operator: "=",
              Value: module,
            },
          ],
        },
      ],
      Exclude: [],
      WhereRaw: "",
      WhereLike: `%[${moduleId}]%`,
      WhereLikeFields: ["LogDesc", "Module"],
    };

    const response = await getProcessLogList(uriQueryString);

    if (response) {
      setActivityLogData(response.Data);
      setIsLoading(false);
    }
  }
  React.useEffect(() => {
    if (props.location.pathname.search("create") !== -1) {
      return;
    }
    if (!activityLogData) {
      getActivityLog(module, moduleId);
    }
  }, [activityLogData, module, moduleId, props.location.pathname]);

  if (props.location.pathname.search("create") !== -1) {
    return null;
  }

  const SwitchColor = (action) => {
    switch (action) {
      case "Add":
        return <span style={{ color: "#4caf50" }}>{action}</span>;
      case "Update":
        return <span style={{ color: "#f8bb86" }}>{action}</span>;
      case "Void":
        return <span style={{ color: "red" }}>{action}</span>;
      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Box>
        <Typography variant="h6" className={classes.content}>
          {translate("ra.module.Activity Log")}
        </Typography>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className={classes.content}>
            <Timeline align="alternate">
              {activityLogData?.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot />
                    {index === activityLogData.length - 1 ? null : <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography color="textSecondary">{DateTimeToString(item.LastModified)}</Typography>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1">
                        Action : {SwitchColor(item.Action)}
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon>person</Icon> {item.UserModified}
                      </div>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
        )}

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(activityLogData, 0, 2) : ""}</pre>
      </Box>
    </ThemeProvider>
  );
};

const DrawerRight = (props) => {
  const {
    state,
    setState,
    open,
    close,
    dataDim,
    dataDetail,
    dataInvoice,
    dataTax,
    module,
    moduleId,
    modify,
    update,
    wfHisList,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const [openWfDialog, setOpenWfDialog] = useState(false);
  const [openInDialog, setOpenInDialog] = useState(false);
  const { DateToString, DateTimeToString } = useContext(GblContext);

  return (
    <Drawer
      open={open}
      onClose={() => {
        close();
        setState({ ...state, dimension: !open, tax: !open, comment: !open, activityLog: !open });
      }}
      anchor="right"
    >
      <div style={{ width: 420 }}>
        {!state.comment && !state.activityLog && dataTax ? (
          <>
            <TaxOpen dataTax={dataTax} module={module} />
            <Divider />
          </>
        ) : null}
        {!state.comment && !state.activityLog && dataInvoice && module === "AP_Payment" ? (
          <>
            <Box>
              <Typography variant="h6" className={classes.content}>
                {translate("ra.module.Invoice")}
                <span
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   setOpenInDialog(!openInDialog);
                  // }}
                  style={{
                    marginLeft: 10,
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "black", // Initial color
                    transition: "color 0.3s", // Add a smooth transition effect
                  }}
                  onMouseEnter={(e) => {
                    // Change color to blue on hover
                    e.target.style.color = "blue";
                  }}
                  onMouseLeave={(e) => {
                    // Reset color on mouse leave
                    e.target.style.color = "black";
                  }}
                >
                  {dataInvoice.InvhSeq}
                </span>
              </Typography>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Invoice No.")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader.InvhInvNo}
                  fullWidth
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Vendor")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader.VnCode}
                  fullWidth
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Currency")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  inputProps={{ style: { textAlign: "right" } }}
                  value={dataInvoice?.InvoiceHeader.CurCode}
                />
                <TextField
                  label={translate("ra.field.Rate")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  inputProps={{ style: { textAlign: "right" } }}
                  value={dataInvoice?.InvoiceHeader.CurRate}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Input Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={DateToString(dataInvoice?.InvoiceHeader.InvhDate)}
                />
                <TextField
                  label={translate("ra.field.Invoice Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  value={DateToString(dataInvoice?.InvoiceHeader.InvhInvDate)}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Credit")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader.InvhCredit}
                />
                <TextField
                  label={translate("ra.field.Due Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  value={DateToString(dataInvoice?.InvoiceHeader.InvhDueDate)}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Tax Inv No.")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader.InvhTInvNo}
                />
                <TextField
                  label={translate("ra.field.Tax Status")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  value={dataInvoice?.InvoiceHeader.TaxStatus}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Tax Inv Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={DateToString(dataInvoice?.InvoiceHeader.InvhTInvDt)}
                />
                <TextField
                  label={translate("ra.field.Tax Period")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  value={dataInvoice?.InvoiceHeader.TaxPeriod}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Description")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader.InvhDesc}
                  multiline
                  fullWidth
                />
              </Box>
            </Box>
            <Divider />
          </>
        ) : null}
        {!state.comment && !state.activityLog && dataInvoice && module === "AR-Receipt" ? (
          <>
            <Box>
              <Typography variant="h6" className={classes.content}>
                {translate("ra.module.Invoice")}
                <a
                  href={`#/arInvoice/${dataInvoice?.InvoiceHeader?.InvhSeq}/show`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: 10 }}
                >
                  {dataInvoice?.InvoiceHeader?.InvhSeq}
                </a>
              </Typography>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Invoice No.")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader?.InvNo}
                  fullWidth
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={DateToString(dataInvoice?.InvoiceHeader?.InvhDate)}
                  fullWidth
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.A/R No.")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={`${dataInvoice?.InvoiceHeader?.ArNo}`}
                  fullWidth
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Currency")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  inputProps={{ style: { textAlign: "right" } }}
                  value={dataInvoice?.InvoiceHeader?.CurrCode}
                />
                <TextField
                  label={translate("ra.field.Rate")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  inputProps={{ style: { textAlign: "right" } }}
                  value={dataInvoice?.InvoiceHeader?.CurrRate}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Due Date")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={DateToString(dataInvoice?.InvoiceHeader?.InvhDueDate)}
                />
              </Box>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Tax Inv No.")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader?.InvhTaxNo}
                />
                <TextField
                  label={translate("ra.field.Tax Status")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  style={{ marginLeft: 6 }}
                  value={dataInvoice?.InvoiceHeader?.TaxType}
                />
              </Box>

              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Description")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={dataInvoice?.InvoiceHeader?.InvhDesc}
                  multiline
                  fullWidth
                />
              </Box>
            </Box>
            <Divider />
          </>
        ) : null}
        {!state.comment && !state.activityLog && dataDetail ? (
          <>
            <Box>
              <Typography variant="h6" className={classes.content}>
                {translate("ra.message.details")}
              </Typography>
              <Box mx={0.5} p={0.5}>
                <TextField
                  label={translate("ra.field.Comment")}
                  variant="outlined"
                  margin="dense"
                  disabled
                  //inputProps={{ style: { textAlign: "right" } }}
                  value={dataDetail?.Description}
                  multiline
                  fullWidth
                />
              </Box>
              {dataDetail.CrBase > 0 && (
                <>
                  <Box mx={0.5} p={0.5}>
                    <TextField
                      label={translate("ra.field.Cr. Amount")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.CrAmount}
                      fullWidth
                    />
                  </Box>
                  <Box
                    mx={0.5}
                    p={0.5}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      label={translate("ra.field.Currency")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      // InputProps={{
                      //   inputComponent: NumberFormatInput,
                      // }}
                      value={dataDetail?.CurCode}
                      style={{ width: "49%" }}
                    />
                    <TextField
                      label={translate("ra.field.Rate")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.CurRate}
                      style={{ width: "49%" }}
                    />
                  </Box>
                  <Box mx={0.5} p={0.5}>
                    <TextField
                      label={translate("ra.field.Cr. Base")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.CrBase}
                      fullWidth
                    />
                  </Box>
                </>
              )}
              {dataDetail.DrBase > 0 && (
                <>
                  <Box mx={0.5} p={0.5}>
                    <TextField
                      label={translate("ra.field.Dr. Amount")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.DrAmount}
                      fullWidth
                    />
                  </Box>
                  <Box
                    mx={0.5}
                    p={0.5}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      label={translate("ra.field.Currency")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      // InputProps={{
                      //   inputComponent: NumberFormatInput,
                      // }}
                      value={dataDetail?.CurCode}
                      style={{ width: "49%" }}
                    />
                    <TextField
                      label={translate("ra.field.Rate")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.CurRate}
                      style={{ width: "49%" }}
                    />
                  </Box>
                  <Box mx={0.5} p={0.5}>
                    <TextField
                      label={translate("ra.field.Dr. Base")}
                      variant="outlined"
                      margin="dense"
                      disabled
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        inputComponent: NumberFormatInput,
                      }}
                      value={dataDetail?.DrBase}
                      fullWidth
                    />
                  </Box>
                </>
              )}
            </Box>
            <Divider />
          </>
        ) : null}
        {!state.comment && !state.activityLog && dataDim && dataDim.length > 0 ? (
          <Box>
            <Typography variant="h6" className={classes.content}>
              {translate("ra.module.Dimension")}
            </Typography>
            <DimensionContent data={dataDim} update={update} modify={modify} />
          </Box>
        ) : null}

        {!state.comment && state.activityLog ? (
          <ActivityOpen module={module} moduleId={moduleId} DateTimeToString={DateTimeToString} {...props} />
        ) : (
          ""
        )}

        {!state.activityLog && state.comment ? <CommentOpen module={module} moduleId={moduleId} {...props} /> : ""}
      </div>
      {openWfDialog && (
        <DialogWorkflowHis
          title="Workflow History"
          data={wfHisList}
          open={openWfDialog}
          onClose={() => setOpenWfDialog(false)}
        />
      )}
      {openInDialog && (
        <DialogViewInvoice open={openInDialog} onClose={() => setOpenInDialog(false)} id={`${dataInvoice.InvhSeq}`} />
      )}
    </Drawer>
  );
};

const PersistentDrawerRight = (props) => {
  const { open, ShowDim, module, wfHisList } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    dimension: false,
    tax: false,
    comment: false,
    activityLog: false,
  });
  const [openWfDialog, setOpenWfDialog] = useState(false);

  if (open) {
    return <DrawerRight {...props} state={state} setState={setState} />;
  } else {
    return (
      <Drawer variant="permanent" className={classes.drawerClose} classes={classes.drawerClose} anchor="right">
        <div className={classes.toolbar}></div>
        <>
          {wfHisList?.length > 0 && (
            <>
              <div className={classes.divIcon}>
                <IconButton
                  onClick={() => {
                    setOpenWfDialog(true);
                  }}
                >
                  <Icon>history</Icon>
                </IconButton>
              </div>
              <Divider />
            </>
          )}
          {module && (
            <>
              <div className={classes.divIcon}>
                <IconButton
                  onClick={() => {
                    setState({ ...state, comment: !state.comment });
                    ShowDim();
                  }}
                >
                  <Icon>comment</Icon>
                </IconButton>
              </div>
              <div className={classes.divIcon}>
                <IconButton
                  onClick={() => {
                    setState({ ...state, activityLog: !state.activityLog });
                    ShowDim();
                  }}
                >
                  <Icon>manage_history</Icon>
                </IconButton>
              </div>
            </>
          )}
        </>
        {openWfDialog && (
          <DialogWorkflowHis
            title="Workflow History"
            data={wfHisList}
            open={openWfDialog}
            onClose={() => setOpenWfDialog(false)}
          />
        )}
      </Drawer>
    );
  }
};

export default withRouter(PersistentDrawerRight);
