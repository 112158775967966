import React, { useContext, useState, useEffect, useMemo } from "react";
import { GblContext } from "providers/formatter";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";
import { Grid, Button, Box, Typography, Divider, IconButton, Icon } from "@material-ui/core";
import DimensionContent from "components/DimensionContent";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "10px 12px",
  },
  root2: {
    flexGrow: 1,
    margin: "5px 12px",
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function PopupTable({
  children,
  open,
  save,
  cancel,
  initialValues,
  checkTaxType,
  update,
  formFields,
  formFieldsTax1,
  formFieldsTax2,
  showDim,
  maxWidth = "sm",
}) {
  const classes = useStyles();
  const translate = useTranslate();
  const { DateToString } = useContext(GblContext);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return initialValues;
    }, [initialValues]),
  });
  const { handleSubmit, getValues, watch, reset } = methods;
  const [disableBtn, setDisableBtn] = useState(false);

  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  const onSubmit = (newFormValue) => {
    setDisableBtn(true);
    if (checkTaxType) {
      checkTaxType(methods, newFormValue);
      if (Object.keys(methods.errors).length === 0) {
        const newArr = Object.assign({}, initialValues, newFormValue);
        save(newArr);
      }
    } else {
      const newArr = Object.assign({}, initialValues, newFormValue);
      save(newArr);
    }

    setTimeout(() => {
      setDisableBtn(false);
    }, 1000);
  };

  const updateForm = (e) => {
    const values = getValues();
    update(e.target.name, methods, values);
  };

  const openTax1Default = () => {
    if (typeof watch("InvdTaxT1") !== "undefined") {
      if (watch("InvdTaxT1") !== "None") {
        return true;
      } else {
        return false;
      }
    } else {
      if (watch("TaxType1") !== "None") {
        return true;
      } else {
        return false;
      }
    }
  };

  const openTax2Default = () => {
    if (typeof watch("InvdTaxT2") !== "undefined") {
      if (watch("InvdTaxT2") !== "None") {
        return true;
      } else {
        return false;
      }
    } else {
      if (watch("TaxType2") !== "None") {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            cancel(event);
          }
        }}
        scroll={"paper"}
        maxWidth={maxWidth}
      >
        <DialogTitle id="scroll-dialog-title">
          {initialValues?.index !== undefined && initialValues?.index !== -1
            ? "Edit"
            : initialValues?.Id
            ? "Edit"
            : "Add"}
          {cancel ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={cancel}>
              <Icon>close</Icon>
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <form>
            <div className={classes.root}>
              <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                {formFields
                  ? formFields.map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: updateForm,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </div>
            {formFieldsTax1 && (
              <div className={classes.root2}>
                <Accordion defaultExpanded={openTax1Default()} style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="tax1-content" id="tax1-header">
                    <Typography>{translate("ra.field.Tax 1")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingTop: 0 }}>
                    <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                      {formFieldsTax1
                        ? formFieldsTax1.map((item, idx) => (
                            <Grid item xs={item.size} key={idx} style={item.style}>
                              {React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: updateForm,
                                },
                              })}
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            {formFieldsTax2 && (
              <div className={classes.root2}>
                <Accordion defaultExpanded={openTax2Default()} style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="tax2-content" id="tax2-header">
                    <Typography>{translate("ra.field.Tax 2")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingTop: 0 }}>
                    <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                      {formFieldsTax2
                        ? formFieldsTax2.map((item, idx) => (
                            <Grid item xs={item.size} key={idx} style={item.style}>
                              {React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: updateForm,
                                },
                              })}
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
            {children}
            {showDim && initialValues?.DimList?.Dim.length > 0 ? (
              <>
                <Divider />
                <Box style={{ margin: 10 }}>
                  <Typography variant="h6" className={classes.content}>
                    {translate("ra.module.Dimension")}
                  </Typography>
                  <DimensionContent
                    typeOfFlex={"row"}
                    data={initialValues?.DimList?.Dim}
                    update={(item, value) => {
                      const DimListDetail = initialValues?.DimList?.Dim;
                      [...DimListDetail].forEach((i) => {
                        if (i.Id === item.Id) {
                          i.Value = value;
                          if (i.Type === "Date") {
                            i.Value = DateToString(value);
                          }
                        }
                      });
                      const Dim = {
                        Dim: DimListDetail,
                      };
                      methods.setValue("DimList", Dim);
                      forceUpdate();
                    }}
                    modify={true}
                  />
                </Box>
              </>
            ) : (
              ""
            )}
          </form>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(watch(), 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={disableBtn}
          >
            {translate("ra.actionMenu.OK")}
          </Button>
          <Button variant="outlined" onClick={cancel}>
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
