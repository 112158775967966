import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Typography, Button, Paper, Divider } from "@material-ui/core";
import { updateSettingAsset } from "services/setting";
import { permissionName } from "utils/constants";
import DialogSettingAccounts from "pages/settings/Dialog/SettingAccountList";
import DatePickerFormat from "components/DatePickerFormat";
import { addDays, addMonths, startOfMonth, endOfMonth, differenceInMonths } from "date-fns";
import { closePeriodAsset, postAp, postReceiving } from "services/assetProcedure";
import { getSettingAll } from "services/setting";
import ErrorTableDialog from "components/ErrorTable";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: theme.spacing(1, 0, 1),
  },
  title2: {
    fontSize: 14,
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
  menuPaper: {
    maxHeight: 200,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions } = props;
  const { settingAll, UpdateSettingAll, DateToString, ToMySqlDate } = useContext(GblContext);
  const { SettingClosePeriod, SettingAsset } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  const [openSettingAcc, setOpenSettingAcc] = useState(false);
  const timer = React.useRef();

  const [loading, setLoading] = useState({
    ap: false,
    receiving: false,
    closedPeriod: false,
  });
  const [filterDate, setFilterDate] = useState({
    from: startOfMonth(addDays(new Date(ClosePeriodAsset), 1)),
    to: endOfMonth(addDays(new Date(ClosePeriodAsset), 1)),
  });
  const [dPeriod, setDPeriod] = useState({
    last: new Date(ClosePeriodAsset),
    current: endOfMonth(addMonths(new Date(ClosePeriodAsset), 1)),
  });
  const [errorTable, setErrorTable] = useState(false);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [errorKeyTable, setErrorKeyTable] = useState();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  React.useEffect(() => {
    const currentAsset = endOfMonth(addMonths(new Date(ClosePeriodAsset), 1));
    setDPeriod((state) => ({
      ...state,
      last: new Date(ClosePeriodAsset),
      current: currentAsset,
    }));
  }, [ClosePeriodAsset]);

  const handleClickClosePeriod = async () => {
    if (!permissions.find((i) => i.Name === permissionName["Ast.Procedure.PeriodEnd"])?.Update) {
      SnackbarUtils.error(translate("ra.permission.denied"));
      return;
    }
    const currentAsset = addMonths(new Date(ClosePeriodAsset), 1);
    const msg = `Do you want to close period ${DateToString(endOfMonth(currentAsset))} ?`;
    SnackbarUtils.confirm(msg, async function () {
      if (!loading.closedPeriod) {
        setLoading((state) => ({
          ...state,
          closedPeriod: true,
        }));
        const closeDate = ToMySqlDate(endOfMonth(currentAsset));
        const { Code, UserMessage } = await closePeriodAsset(closeDate);
        if (Code >= 0) {
          SnackbarUtils.success(UserMessage);
          const setting = await getSettingAll();
          localStorage.setItem("SettingAll", JSON.stringify(setting));
          UpdateSettingAll(setting);

          const currentAsset = addDays(new Date(setting.SettingClosePeriod.ClosePeriodAsset), 1);

          setDPeriod((state) => ({
            ...state,
            last: new Date(setting.SettingClosePeriod.ClosePeriodAsset),
            current: currentAsset,
          }));

          setFilterDate((state) => ({
            ...state,
            from: startOfMonth(currentAsset),
            to: endOfMonth(currentAsset),
          }));
        } else {
          SnackbarUtils.warning(UserMessage);
        }
        timer.current = window.setTimeout(() => {
          setLoading((state) => ({
            ...state,
            closedPeriod: false,
          }));
        }, 1000);
      }
    });
  };

  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage, ErrorList }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }

    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      if (ErrorList?.length > 0) {
        setErrorKeyTable(Object.keys(ErrorList[0]));
        setErrorTable(ErrorList);
        setShowErrorTable(true);
      }
    }
    if (Code === -1) {
      SnackbarUtils.warning(InternalMessage);
      if (ErrorList?.length > 0) {
        setErrorKeyTable(Object.keys(ErrorList[0]));
        setErrorTable(ErrorList);
        setShowErrorTable(true);
      }
    }
    if (Code === -204) {
      SnackbarUtils.warning(UserMessage);
    }
  };

  const handleClickPost = async (name) => {
    setLoading((state) => ({
      ...state,
      [name]: true,
    }));
    const fromDate = ToMySqlDate(filterDate.from);
    const toDate = ToMySqlDate(filterDate.to);

    const diffMonth = differenceInMonths(filterDate.to, filterDate.from);

    if (diffMonth > 0) {
      SnackbarUtils.warning("Can only post one month at a time.");
      setLoading((state) => ({
        ...state,
        [name]: false,
      }));
      return;
    }

    switch (name) {
      case "AP": {
        if (!permissions.find((i) => i.Name === permissionName["Ast.Post.AP"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        }
        const r = await postAp(fromDate, toDate);
        if (r.Code === -200) {
          const msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postAp(fromDate, toDate, true);
              CheckResultShowMessage(rr);
            },
            null
          );
        }
        CheckResultShowMessage(r);
        break;
      }

      case "Receiving": {
        // if (
        // 	!permissions.find((i) => i.Name === permissionName["Ast.Post.Receiving"])?.View
        // ) {
        // 	SnackbarUtils.error(translate("ra.permission.denied"));
        // 	return;
        // }

        const param = {
          FromDate: fromDate,
          ToDate: toDate,
        };

        const r = await postReceiving(param);
        if (r.Code === -200) {
          const msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postReceiving(param, true);
              CheckResultShowMessage(rr);
            },
            null
          );
        }
        CheckResultShowMessage(r);
        break;
      }

      default:
        break;
    }

    // const r = await postAp(fromDate, toDate);

    timer.current = window.setTimeout(() => {
      setLoading((state) => ({
        ...state,
        [name]: false,
      }));
    }, 1000);
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {translate(`ra.actionMenu.${text}`)}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{
          paddingTop: 5,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <Typography variant="h6" className={classes.title}>
          {translate("ra.module.Procedure")}
        </Typography>

        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">
              {translate("ra.module.Posting from") + translate("ra.module.Account Payable")}
            </Typography>
            {/* <Typography variant="caption">Posting from Account Payable</Typography> */}
          </Box>
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={filterDate.from}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: e > filterDate.to ? e : filterDate.to,
                }));
              }}
              minDate={new Date(ClosePeriodAsset)}
              minDateMessage={"Date must be more than close period"}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={filterDate.to}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={filterDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          <Divider />
          <Box p={1}>
            <LoadingButton text="POST" disabled={loading.receiving} fnc={() => handleClickPost("AP")} />
          </Box>
        </Box>
        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">
              {translate("ra.module.Posting from") + translate("ra.module.Receiving")}
            </Typography>
            {/* <Typography variant="caption">Posting from Account Payable</Typography> */}
          </Box>
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={filterDate.from}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: e > filterDate.to ? e : filterDate.to,
                }));
              }}
              minDate={new Date(ClosePeriodAsset)}
              minDateMessage={"Date must be more than close period"}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={filterDate.to}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={filterDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          <Divider />
          <Box p={1}>
            <LoadingButton text="POST" disabled={loading.ap} fnc={() => handleClickPost("Receiving")} />
          </Box>
        </Box>
        {/* <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">Set Asset Account Code for Posting</Typography>
         
          <Box>
            <Button variant="outlined" onClick={() => setOpenSettingAcc(true)}>
              Set Account Code
            </Button>
          </Box>
        </Box> */}
        <Divider />
        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="h6" className={classes.title2} style={{ margin: 0 }}>
              {translate("ra.module.Close Period")}
            </Typography>
            {/* <Typography variant="caption">Asset Close Period</Typography> */}
          </Box>
          <Box>
            <DatePickerFormat
              label={translate("ra.field.Last Closed Period")}
              value={dPeriod.last}
              readOnly
              style={{ width: 162, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.Current Period")}
              value={dPeriod.current}
              readOnly
              style={{ width: 162, margin: "0 10px" }}
            />
          </Box>
          <Box p={1} display="flex" alignItems="center">
            <LoadingButton text="CLOSE" disabled={loading.closedPeriod} fnc={handleClickClosePeriod} />
          </Box>
        </Box>
        {/* <Divider /> */}
      </Paper>
      {showErrorTable && (
        <ErrorTableDialog
          open={showErrorTable}
          onClose={() => setShowErrorTable(false)}
          errorTable={errorTable}
          keyTable={errorKeyTable}
        />
      )}
      {SettingAsset && (
        <DialogSettingAccounts
          title={"Setting Account for Posting"}
          open={openSettingAcc}
          onClose={async (e, accList) => {
            setOpenSettingAcc(false);
            if (accList) {
              SettingAsset.ApPostingAccounts = JSON.stringify(accList);
              const { Code, UserMessage } = await updateSettingAsset(SettingAsset);
              if (Code === 0) {
                SnackbarUtils.success(UserMessage);
                settingAll.SettingAsset = SettingAsset;
                localStorage.setItem("SettingAll", JSON.stringify(settingAll));
                UpdateSettingAll(settingAll);
              } else {
                SnackbarUtils.warning(UserMessage);
              }
            }
          }}
          currentAcc={SettingAsset.ApPostingAccounts}
        />
      )}
    </div>
  );
};

export default List;
