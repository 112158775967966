/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
//import clsx from "clsx";
import { Loading, useRedirect } from "react-admin";
import { Paper, Grid, Typography, Card, CardContent, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import ButtonUpload from "components/ButtonUpload";
import NavRight from "components/NavRightSide";
import CardImage from "components/CardImage";
import PreviewImage from "assets/previewImage.png";
import { getAssetRegDetail, updateAssetRegDetail } from "services/asset";
import SnackbarUtils from "utils/SnackbarUtils";
import fileReader from "utils/fileReader";
import Compressor from "compressorjs";

const Edit = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { settingAll, DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  const redirect = useRedirect();
  const { basePath, id, formFields, formFieldsRegister, formFieldsRemainValue, formFieldsAccount } = props;
  const [data, setData] = useStateWithCallbackLazy();
  const [loading, setLoading] = useState(true);

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Disposal", disabled: true },
    { name: "Pre-Asset", disabled: true },
    { name: "Print", disabled: true },
    { name: "Print BarCode", disabled: true },
  ];

  const methods = useForm({ defaultValues: data });

  const { handleSubmit, getValues, setValue, reset } = methods;

  const fetchAssetRegById = useCallback(async () => {
    setLoading(true);
    const response = await getAssetRegDetail(id);
    if (response) {
      const Qty = ToNumber(response["Qty"]);
      const Salvage = ToNumber(response["Salvage"]);
      response.TotalSalvage = NumberFormat(Salvage * Qty);
      let lifeType;
      switch (response["LifeType"]) {
        case 1:
          lifeType = "Month";
          break;
        case 2:
          lifeType = "Day";
          break;
        default:
          lifeType = "Year";
          break;
      }
      response.LifeType = lifeType;
      setData(response);
      reset(response);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [id, reset]);

  useEffect(() => {
    fetchAssetRegById();
  }, [fetchAssetRegById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));

    let lifeType;
    //Switch Value AssetLifeType
    switch (values["LifeType"]) {
      case "Month":
        lifeType = 1;
        break;
      case "Day":
        lifeType = 2;
        break;
      default:
        lifeType = 0;
        break;
    }
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        LifeType: lifeType,
        RemainQty: values.Qty,
        RemainTotalCost: values.RemainNet,
      }),
      (nextState) => Save(nextState)
    );
  };

  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();

  const UpdateForm = (e) => {
    const values = getValues();
    if (e.target.name === "InputDate") {
      const inputDate = new Date(e.target.value);
      const acquireDate = typeof values.AcquireDate === "string" ? new Date(values.AcquireDate) : values.AcquireDate;
      if (inputDate < acquireDate) {
        setValue("AcquireDate", inputDate);
      }
      setValue("TransferDate", inputDate);
    }
  };

  const CalculateForm = (e) => {
    const values = getValues();
    const CurRate = ToNumber(values["CurRate"]);
    const Amount = ToNumber(values["Amount"]);
    const Qty = ToNumber(values["Qty"]);
    const InitAccu = ToNumber(values["InitAccu"]);
    const Salvage = ToNumber(values["Salvage"]);
    const TotalSalvage = NumberFormat(Salvage * Qty);

    const BaseAmount = NumberFormat(Amount * CurRate);
    const TotalValue = NumberFormat(Amount * CurRate * Qty);
    const NetBookValue = NumberFormat(Amount * CurRate * Qty);

    methods.setValue("BaseAmount", BaseAmount);
    methods.setValue("TotalCost", TotalValue);
    methods.setValue("TotalSalvage", TotalSalvage);
    methods.setValue("RemainNet", ToNumber(NetBookValue) - InitAccu);
  };

  const Save = async (values) => {
    //Validate & CheckDetail
    const { Code, InternalMessage, UserMessage } = await updateAssetRegDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect("show", basePath, id, values);
      });
    } else {
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
    setLoading(false);
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim(data.DimList.Dim);
      setOpenDim(!openDim);
    } else {
      setDataDim(values);
      setOpenDim(!openDim);
    }
  };

  const CancelFnc = () => {
    redirect("show", basePath, id);
  };

  const UploadImg = (e) => {
    if (e.target.files.length > 0) {
      const msg = fileReader.CheckLimitImgAssetSize(e.target.files);
      if (msg) {
        document.getElementById("ast_img").value = "";
        SnackbarUtils.error(translate(msg, { size: "1" }));
        return;
      }

      const file = e.target.files[0];

      new Compressor(file, {
        quality: 0.6,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
            setData((state) => ({
              ...state,
              AstPhoto: base64String,
            }));
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  if (loading) return <Loading />;
  if (!data) return null;

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Card>
          <CardContent>
            <Grid container alignItems="center" justifyContent="center" spacing={1}>
              <Grid item xs={9}>
                <BoxHeader header={"Asset Register"} />
              </Grid>
              <Grid item xs={3}>
                <ButtonUpload
                  id="ast_img"
                  name="ast_img"
                  style={{ display: "none" }}
                  type="file"
                  onChange={UploadImg}
                  accept="image/png, image/gif, image/jpeg"
                  btntext="Choose Image"
                  maxSize={1}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={9}>
                <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: "auto" }}>
                  {formFields
                    ? formFields.map((item, idx) => (
                        <Grid item xs={item.size} key={idx} style={item.style}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              onChange: UpdateForm,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: 8, textAlign: "center" }}>
                  {data.AstPhoto ? (
                    <CardImage base64Src={data.AstPhoto} noBorder={true} customSize={{ height: 200 }} />
                  ) : (
                    <CardImage imgSrc={PreviewImage} noBorder={true} customSize={{ height: 200 }} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={4} />

        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Register Value</Typography>
                <Box height={"0.5rem"} />
                <Grid container alignItems="flex-start" spacing={1}>
                  {formFieldsRegister
                    ? formFieldsRegister.map((item, idx) => (
                        <Grid item xs={item.size} key={idx}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              onChange: CalculateForm,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>Remain Value @ {DateToString(ClosePeriodAsset)}</Typography>
                  <Box height={"0.5rem"} />
                  <Grid container alignItems="flex-start" spacing={1}>
                    {formFieldsRemainValue
                      ? formFieldsRemainValue.map((item, idx) => (
                          <Grid item xs={2} key={idx}>
                            {React.createElement(item.field.type, {
                              ...{
                                ...item.field.props,
                                methods,
                                key: item.field.props.name,
                                //onChange: UpdateForm,
                              },
                            })}
                          </Grid>
                        ))
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />
        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Asset Account</Typography>
                <Box height={"0.5rem"} />
                <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ marginBottom: 12 }}>
                  {formFieldsAccount
                    ? formFieldsAccount.map((item, idx) => (
                        <Grid item xs={item.size} key={idx}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        <ButtonFooter CancelFnc={CancelFnc} />
      </form>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        update={(item, value) => {
          dataDim.forEach((i) => {
            if (i.Id === item.Id) {
              i.Value = value;
              if (i.Type === "Date") {
                i.Value = DateToString(value);
              }
            }
          });
          setDataDim(dataDim);
        }}
        modify
        module={"AST_Reg"}
        moduleId={id}
      />
    </div>
  );
};

export default Edit;
