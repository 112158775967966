import React, { useContext, useState, useCallback } from "react";
import { Loading, useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Paper, Typography, Box, Divider, Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DatePickerFormat from "components/DatePickerFormat";
import DialogChequeReconcil from "./DialogChequeReconcil";
import DialogVATReconcil from "./DialogVATReconcil";
import DialogWhtReconcil from "./DialogWhtReconcil";
import { permissionName } from "utils/constants";
import { format, addDays, addMonths, startOfMonth, endOfMonth, differenceInMonths } from "date-fns";
import { closePeriodAp, postReceiving } from "services/apProcedure";
import { getLicenseList, getSettingAll } from "services/setting";
import { getListApPeriod } from "services/callStoreProcedure";
import { getSettingInfPost } from "services/interface";
import DialogPMS from "./Dialog/DialogPMS";
import ErrorListDialog from "components/ErrorList";
import ErrorTableDialog from "components/ErrorTable";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: theme.spacing(1, 0, 1),
  },
  title2: {
    fontSize: 14,
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: 500,
  },
  menuPaper: {
    maxHeight: 200,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  lucnchIconStyle: {
    marginLeft: 8,
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions } = props;
  const { settingAll, UpdateSettingAll, DateToString, ToMySqlDate } = useContext(GblContext);
  const { SettingClosePeriod, SettingAp } = settingAll;
  const { ClosePeriodAp } = SettingClosePeriod;
  const timer = React.useRef();
  const [loading, setLoading] = useState({ page: true, closedPeriod: false, rec: false });

  const [taxPeriodList, setTaxPeriodList] = useState();
  const [filterDate, setFilterDate] = useState({
    from: startOfMonth(addDays(new Date(ClosePeriodAp), 1)),
    to: endOfMonth(addDays(new Date(ClosePeriodAp), 1)),
  });

  const [interfacePermissionList, setInterfacePermissionList] = useState();

  const [openDialog, setOpenDialog] = useState({
    dialogCheuqe: false,
    dialogVat: false,
    dialogEditVat: false,
    dialogWht: false,
  });
  const [dPeriod, setDPeriod] = useState({
    last: new Date(ClosePeriodAp),
    current: endOfMonth(addMonths(new Date(ClosePeriodAp), 1)),
  });
  const [errorList, setErrorList] = useState(false);
  const [errorTable, setErrorTable] = useState(false);
  const [showErrorList, setShowErrorList] = useState(false);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [errorKeyTable, setErrorKeyTable] = useState();
  const [useDateFromBank, setUseDateFromBank] = useState(true);
  const [clearingDate, setClearingDate] = useState(new Date());
  const [settingInf, setSettingInf] = useState([]);

  const getLicenseInterface = useCallback(async () => {
    const { InterfacePermissionItems } = await getLicenseList();
    const newArr = InterfacePermissionItems.filter((item) => item.CanUse);
    const keyIdArr = newArr.map((item) => item.UniqueId.Id);
    keyIdArr.forEach((item) => {
      setLoading((state) => ({
        ...state,
        [item]: false,
      }));
    });
    const Inventory = newArr.filter((item) => item.UniqueId.LicenseType === "Inventory");

    const settingInfs = await getSettingInfPost("AP");
    if (settingInfs) {
      const Oinventory = settingInfs.filter(
        (item) =>
          item.InterfaceType === "Inventory" &&
          item.InterfaceName.toLowerCase() !== "blueledger" &&
          item.InterfaceName.toLowerCase() !== "blueledgers"
      );
      const keyinventoryArr = Oinventory.map((item) => item.Code);

      if (Oinventory.length > 0) {
        Oinventory.forEach((element) => {
          if (element.IsActive) {
            Oinventory.HasChildren = true;
          }
        });
      }
      setSettingInf(settingInfs);

      keyinventoryArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });

      setInterfacePermissionList((state) => ({
        ...state,
        Inventory: Inventory,
        OtherInventory: Oinventory,
      }));
      setLoading((state) => ({
        ...state,
        page: false,
      }));
    } else {
      setInterfacePermissionList((state) => ({
        ...state,
        Inventory: Inventory,
        OtherInventory: [],
      }));

      setLoading((state) => ({
        ...state,
        page: false,
      }));
    }
  }, []);

  React.useEffect(() => {
    getLicenseInterface();
    return () => {
      clearTimeout(timer.current);
    };
  }, [getLicenseInterface]);

  const fetchTaxPeriodLookup = async () => {
    var arr = await getListApPeriod();
    var periodList = arr.map((item) => format(new Date(item.PeriodDate), "MM/yyyy"));
    if (periodList.length > 0) {
      setTaxPeriodList(periodList);
    }
  };

  React.useEffect(() => {
    let currentAp = endOfMonth(addMonths(new Date(ClosePeriodAp), 1));
    setDPeriod((state) => ({
      ...state,
      last: new Date(ClosePeriodAp),
      current: currentAp,
    }));
    fetchTaxPeriodLookup();
  }, [ClosePeriodAp]);

  const handleClickClosePeriod = async () => {
    if (!permissions.find((i) => i.Name === permissionName["AP.Procedure.PeriodEnd"])?.Update) {
      SnackbarUtils.error(translate("ra.permission.denied"));
      return;
    }
    let currentAp = addMonths(new Date(ClosePeriodAp), 1);
    let msg = `Do you want to close period ${DateToString(endOfMonth(currentAp))} ?`;
    SnackbarUtils.confirm(msg, async function () {
      if (!loading.closedPeriod) {
        setLoading((state) => ({
          ...state,
          closedPeriod: true,
        }));
        let closeDate = ToMySqlDate(endOfMonth(currentAp));
        const { Code, InternalMessage, UserMessage, ErrorList } = await closePeriodAp(closeDate);
        if (Code >= 0) {
          SnackbarUtils.success(UserMessage);
          const setting = await getSettingAll();
          localStorage.setItem("SettingAll", JSON.stringify(setting));
          UpdateSettingAll(setting);

          let currentAp = addDays(new Date(setting.SettingClosePeriod.ClosePeriodAp), 1);

          setDPeriod((state) => ({
            ...state,
            last: new Date(setting.SettingClosePeriod.ClosePeriodAp),
            current: currentAp,
          }));

          setFilterDate((state) => ({
            ...state,
            from: currentAp,
            to: endOfMonth(currentAp),
          }));
        } else {
          SnackbarUtils.warning(InternalMessage);
          if (ErrorList) {
            setErrorList(ErrorList);
            setShowErrorList(true);
          }
        }
        timer.current = window.setTimeout(() => {
          setLoading((state) => ({
            ...state,
            closedPeriod: false,
          }));
        }, 1000);
      }
    });
  };

  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage, ErrorList }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }

    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      if (ErrorList.length > 0) {
        setErrorKeyTable(Object.keys(ErrorList[0]));
        setErrorTable(ErrorList);
        setShowErrorTable(true);
      }
    }
    if (Code === -1) {
      SnackbarUtils.warning(InternalMessage);
      if (ErrorList.length > 0) {
        setErrorKeyTable(Object.keys(ErrorList[0]));
        setErrorTable(ErrorList);
        setShowErrorTable(true);
      }
    }
    if (Code === -204) {
      SnackbarUtils.warning(UserMessage);
    }
  };

  const handleClickPost = async (name) => {
    if (!permissions.find((i) => i.Name === permissionName["AP.Post.Receiving"])?.View) {
      SnackbarUtils.error(translate("ra.permission.denied"));
      return;
    }
    setLoading((state) => ({
      ...state,
      [name]: true,
    }));
    let param = {
      FromDate: filterDate.from,
      ToDate: filterDate.to,
    };

    const diffMonth = differenceInMonths(filterDate.to, filterDate.from);

    if (diffMonth > 0) {
      SnackbarUtils.warning("Can only post one month at a time.");
      setLoading((state) => ({
        ...state,
        [name]: false,
      }));
      return;
    }

    const r = await postReceiving(param, false);
    if (r.Code === -200) {
      let msg = "Data already post. Do you want to repost ?";
      SnackbarUtils.loadingConfirm(
        msg,
        async function () {
          const rr = await postReceiving(param, true);
          CheckResultShowMessage(rr);
        },
        null
      );
    }
    CheckResultShowMessage(r);

    timer.current = window.setTimeout(() => {
      setLoading((state) => ({
        ...state,
        [name]: false,
      }));
    }, 1000);
  };

  const handleClickOpenDialog = (item) => {
    switch (item.InterfaceType) {
      case "Inventory":
        setOpenDialog((state) => ({
          ...state,
          [`${item.Code}`]: true,
        }));
        break;
      default:
        break;
    }
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {translate(`ra.actionMenu.${text}`)}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  if (loading.page) return <Loading />;

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{
          paddingTop: 5,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <Typography variant="h6" className={classes.title}>
          {translate("ra.module.Procedure")}
        </Typography>

        {interfacePermissionList && interfacePermissionList["Inventory"].length > 0 && (
          <>
            <Box display="flex" alignItems="center">
              <Box p={1} flexGrow={1}>
                <Typography variant="h6" className={classes.title2} style={{ margin: 0 }}>
                  {translate("ra.module.Posting from") + translate("ra.module.Receiving")}
                </Typography>
                {/* <Typography variant="caption">Posting from Receiving</Typography> */}
              </Box>
              <Box p={1}>
                <DatePickerFormat
                  label={translate("ra.field.From")}
                  value={filterDate.from}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      from: e,
                      to: e > filterDate.to ? e : filterDate.to,
                    }));
                  }}
                  minDate={new Date(ClosePeriodAp)}
                  minDateMessage={"Date must be more than close period"}
                  style={{ width: 160, margin: "0 10px" }}
                />
                <DatePickerFormat
                  label={translate("ra.field.To")}
                  value={filterDate.to}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      to: e,
                    }));
                  }}
                  minDate={filterDate.from}
                  minDateMessage={"Date must be more than from date"}
                  style={{ width: 160, margin: "0 38px 0 10px" }}
                />
              </Box>
              <Divider />
              <Box p={1}>
                <LoadingButton text="POST" disabled={loading.rec} fnc={() => handleClickPost("rec")} />
              </Box>
            </Box>
            <Divider />
          </>
        )}

        {interfacePermissionList &&
          interfacePermissionList["OtherInventory"].length > 0 &&
          interfacePermissionList["OtherInventory"].HasChildren && (
            <>
              {interfacePermissionList["OtherInventory"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography
              variant="h6"
              className={classes.title2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!permissions.find((i) => i.Name === permissionName["AP.Procedure.ChequeReconciliation"])?.View) {
                  SnackbarUtils.error(translate("ra.permission.denied"));
                  return;
                }
                setOpenDialog((state) => ({
                  ...state,
                  dialogCheuqe: true,
                }));
              }}
            >
              {translate("ra.module.Cheque Reconciliation")}
              <span className={classes.lucnchIconStyle}>
                <LaunchIcon />
              </span>
            </Typography>
            {openDialog.dialogCheuqe && (
              <DialogChequeReconcil
                translate={translate}
                title={translate("ra.module.Cheque Reconciliation")}
                useDateFromBank={useDateFromBank}
                defaultClearingDate={clearingDate}
                open={openDialog.dialogCheuqe}
                onClose={() =>
                  setOpenDialog((state) => ({
                    ...state,
                    dialogCheuqe: false,
                  }))
                }
              />
            )}
          </Box>
          <Box px={1}>
            <DatePickerFormat
              label={translate("ra.field.Default clearing date")}
              value={clearingDate}
              onChange={(e) => {
                setClearingDate(e);
              }}
              style={{ width: 160 }}
            />
          </Box>
          <Box px={1}>
            <FormControlLabel
              value={useDateFromBank}
              control={
                <Switch
                  checked={useDateFromBank}
                  onChange={(e, newValue) => setUseDateFromBank(newValue)}
                  label={useDateFromBank ? translate("ra.field.Active") : translate("ra.field.In-Active")}
                  //disabled={true}
                />
              }
              label={useDateFromBank ? translate("ra.field.Active") : translate("ra.field.In-Active")}
              labelPlacement="start"
              color="primary"
            />
          </Box>
        </Box>
        <Divider />

        {SettingAp.EnableThaiTaxReconcile && (
          <>
            <Box flexGrow={1}>
              <Typography
                variant="h6"
                className={classes.title2}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!permissions.find((i) => i.Name === permissionName["AP.Procedure.VatReconciliation"])?.View) {
                    SnackbarUtils.error(translate("ra.permission.denied"));
                    return;
                  }
                  setOpenDialog((state) => ({
                    ...state,
                    dialogVat: true,
                  }));
                }}
              >
                {translate("ra.module.Input Tax Reconciliation")}
                <span className={classes.lucnchIconStyle}>
                  <LaunchIcon />
                </span>
              </Typography>
              {openDialog.dialogVat && (
                <DialogVATReconcil
                  translate={translate}
                  title={translate("ra.module.Input Tax Reconciliation")}
                  taxPeriodList={taxPeriodList}
                  open={openDialog.dialogVat}
                  onClose={() =>
                    setOpenDialog((state) => ({
                      ...state,
                      dialogVat: false,
                    }))
                  }
                />
              )}
            </Box>
            <Divider />
            <Box flexGrow={1}>
              <Typography
                variant="h6"
                className={classes.title2}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!permissions.find((i) => i.Name === permissionName["AP.Procedure.WHTReconciliation"])?.View) {
                    SnackbarUtils.error(translate("ra.permission.denied"));
                    return;
                  }
                  setOpenDialog((state) => ({
                    ...state,
                    dialogWht: true,
                  }));
                }}
              >
                {translate("ra.module.Withholding TAX Reconciliation")}
                <span className={classes.lucnchIconStyle}>
                  <LaunchIcon />
                </span>
              </Typography>
              {openDialog.dialogWht && (
                <DialogWhtReconcil
                  translate={translate}
                  title={translate("ra.module.Withholding TAX Reconciliation")}
                  open={openDialog.dialogWht}
                  onClose={() =>
                    setOpenDialog((state) => ({
                      ...state,
                      dialogWht: false,
                    }))
                  }
                />
              )}
            </Box>
            <Divider />
          </>
        )}

        <Box p={1} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" className={classes.title2} style={{ margin: 0 }}>
              {translate("ra.module.Close Period")}
            </Typography>
            {/* <Typography variant="caption">General Ledger Close Period</Typography> */}
          </Box>

          <Box>
            <DatePickerFormat
              label={translate("ra.field.Last Closed Period")}
              value={dPeriod.last}
              readOnly
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.Current Period")}
              value={dPeriod.current}
              readOnly
              style={{ width: 160, margin: "0 48px 0 10px" }}
            />
          </Box>
          {/* <b>Close to date &nbsp;: </b> &nbsp; {DateToString(endMonth)} */}
          <Box display="flex" alignItems="center">
            <LoadingButton text="CLOSE" disabled={loading.closedPeriod} fnc={handleClickClosePeriod} />
          </Box>
        </Box>
      </Paper>
      {showErrorList && (
        <ErrorListDialog
          open={showErrorList}
          onClose={() => setShowErrorList(false)}
          errorList={errorList}
          checkWorkflow={true}
        />
      )}
      {showErrorTable && (
        <ErrorTableDialog
          open={showErrorTable}
          onClose={() => setShowErrorTable(false)}
          errorTable={errorTable}
          keyTable={errorKeyTable}
        />
      )}
    </div>
  );
};

export default List;
