const enCustomMessages = {
  ra: {
    module: {
      "General Ledger": "General Ledger",
      "General Ledgers": "General Ledger",
      "Journal Voucher": "Journal Voucher",
      "Allocation Voucher": "Allocation Voucher",
      "Standard Journal Voucher": "Standard Journal Voucher",
      "Template Voucher": "Template Voucher",
      "Recurring Voucher": "Recurring Voucher",
      "Amortization Voucher": "Amortization Voucher",
      "Amortization Voucher Template": "Amortization Voucher Template",
      "Amortize Account": "Amortize Account",
      "Account Summary": "Account Summary",
      "Financial Report": "Financial Report",
      "Chart of accounts": "Chart of accounts",
      Budget: "Budget",
      "Account Payable": "Accounts Payable",
      "Accounts Payable": "Accounts Payable",
      Vendor: "Vendor",
      Information: "Information",
      "Invoice Default": "Invoice Default",
      "Payment Default": "Payment Default",
      "AutoPay Information": "AutoPay Information",
      Invoice: "Invoice",
      Payment: "Payment",
      "Account Receivable": "Accounts Receivable",
      "Accounts Receivable": "Accounts Receivable",
      Profile: "Profile",
      "A/P Invoice": "A/P Invoice",
      "A/P Payment": "A/P Payment",
      "A/R Profile": "A/R Profile",
      "A/R Contract": "A/R Contract",
      "A/R Folio": "A/R Folio",
      "A/R Invoice": "A/R Invoice",
      "A/R Receipt": "A/R Receipt",
      Folio: "Folio",
      Receipt: "Receipt",
      Asset: "Asset",
      "Pre-Register Asset": "Pre-Register Asset",
      "Asset Management": "Asset Management",
      "Asset management": "Asset management",
      "Asset Register": "Asset Register",
      "Asset Disposal": "Asset Disposal",
      "Asset Impairment": "Asset Impairment",
      Procedure: "Procedure",
      Receiving: "Receiving",
      Inventory: "Inventory",
      "Apply Recurring Template": "Apply Recurring Template",
      "Apply Amortization Template": "Apply Amortization Template",
      "Posting from": "Posting from ",
      "Close Period": "Close Period",
      "Cheque Reconciliation": "Cheque Reconciliation",
      "Input Tax Reconciliation": "Input Tax Reconciliation",
      Reconciliation: "VAT Reconciliation",
      "Edit VAT Reconciliation": "Edit VAT Reconciliation",
      "Withholding TAX Reconciliation": "Withholding Tax Reconciliation",
      "Apply Contract": "Apply Contract",
      Report: "Report",
      Setting: "Setting",
      Configuration: "Configuration",
      "Product License": "Product License",
      "Company Profile": "Company Profile",
      "System Preference": "System Preference",
      Users: "Users",
      Permissions: "Permissions",
      Department: "Department",
      "Payment Type": "Payment Type",
      Currency: "Currency",
      "Currency Exchange Rate": "Currency Exchange Rate",
      Dimension: "Dimension",
      Unit: "Unit",
      Interface: "Interface",
      Email: "Email",
      EmailTemplate: "Email Template",
      Workflow: "Workflow",
      "Activity Log": "Activity Log",
      "Trial Balance": "Trial Balance",
      "Account Detail": "Account Detail",
      "Hotel Information": "Hotel Information",
      "Company Information": "Company Information",
    },
    actionMenu: {
      Back: "Back",
      Add: "Add",
      Edit: "Edit",
      Delete: "Delete",
      Void: "Void",
      Copy: "Copy",
      Template: "Template",
      "Pre-Asset": "Pre-Asset",
      Print: "Print",
      Printed: "Printed",
      Payment: "Payment",
      Receipt: "Receipt",
      Disposal: "Disposal",
      Effective: "Effective",
      Normal: "Normal",
      Draft: "Draft",
      Posted: "Posted",
      POST: "POST",
      CLOSE: "CLOSE",
      APPLY: "APPLY",
      OK: "OK",
    },
    info: {
      Unbalanced: "Unbalanced. Please check debit or credit amount",
      notransaction: "There is no transaction",
      help: "Help",
      maxSizeUpload: "Maximum Upload file size : %{size} Mb",
      maxImageSizeUpload: "Maximum Upload Image size : %{size} Mb",
      reportNotFound: "Report not found",
      Success: "Success",
      Warning: "Warning",
      Information: "Information",
      Error: "Error",
    },
    question: {
      confirmDel: "Confirm deletion ?",
      confirmVoid: "Are you sure you want to VOID this %{module} ?",
      confirmPrintCheque: "Confirm print cheque?",
      currencyWarning: "Do you want to update the currency rate ?",
    },
    field: {
      " ": " ",
      "No.": "No.",
      All: "All",
      "Due in 7 days": "Due in 7 days",
      "Due in this month": "Due in this month",
      "Due in next month": "Due in next month",
      "Unpaid Invoice": "Unpaid Invoice",
      "Outstanding Invoice": "Outstanding Invoice",
      "Paid Invoice": "Paid Invoice",
      "Settled Invoice": "Settled Invoice",
      "Open Period": "Open Period",
      "Period Date": "Period Date",
      User: "User",
      Username: "Username",
      Password: "Password",
      ConfirmPassword: "ConfirmPassword",
      "Business Unit": "Business Unit",
      Language: "Language",
      ViewBy: "ViewBy",
      FilterBy: "FilterBy",
      Date: "Date",
      Prefix: "Prefix",
      "Journal Voucher": "Journal Voucher",
      "Standard Journal Voucher": "Standard Journal Voucher",
      "Journal Voucher No.": "Journal Voucher No.",
      Description: "Description",
      "Description (Local)": "Description",
      "Voucher No.": "Voucher No.",
      "Recurring Every Period": "Recurring Every Period",
      Code: "Code",
      From: "From",
      To: "To",
      FromAcc: "From Acc.",
      ToAcc: "To Acc.",
      Source: "Source",
      Status: "Status",
      Active: "Active",
      "In-Active": "Inactive",
      Account: "Account",
      "Account #": "Account #",
      Department: "Department",
      "Dept.": "Dept.",
      "Department Name": "Department Name",
      "Acc.": "Acc.",
      "Account Code": "Account Code",
      "Account Name": "Account Name",
      Currency: "Currency",
      Rate: "Rate",
      "Rate (%)": "Rate (%)",
      Nature: "Nature",
      Debit: "Debit",
      Credit: "Credit",
      Address: "Address",
      Amount: "Amount",
      "Dr. Amount": "Dr. Amount",
      "Cr. Amount": "Cr. Amount",
      Base: "Base",
      "Dr. Base": "Dr. Base",
      "Cr. Base": "Cr. Base",
      Total: "Total",
      "Total Cost": "Total Cost",
      "Cheq. Date": "Cheq. Date",
      "Cheq. No. From": "Cheq. No. From",
      "Cheq. No. To": "Cheq. No. To",
      "Clearing Date": "Clearing Date",
      "Clearing Amount": "Clearing Amount",
      Comment: "Comment",
      "Base Summary": "Base Summary",
      "A/R Profile": "A/R Profile",
      "A/R No.": "A/R No.",
      "AR No.": "AR No.",
      Title: "Title",
      Type: "Type",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Register Date": "Register Date",
      Name: "Name",
      "Contact Name": "Contact Name",
      "Company Name": "Company Name",
      Company: "Company",
      Position: "Position",
      Telephone: "Telephone",
      "TAX ID.": "TAX ID.",
      "View Invoice": "View Invoice",
      Email: "Email",
      Remark: "Remark",
      Address1: "Address1",
      Address2: "Address2",
      Address3: "Address3",
      Payment: "Payment",
      "Credit Limit": "Credit Limit",
      "Credit Term": "Credit Term",
      Billing: "Billing",
      "Billing To": "Billing To",
      "Mailing To": "Mailing To",
      "Tax Invoice Address": "Tax Invoice Address",
      "A/R Contract": "A/R Contract",
      Contract: "Contract",
      "Contract No.": "Contract No.",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "Start Contract": "Start Contract",
      "End Contract": "End Contract",
      Owner: "Owner",
      Project: "Project",
      "Charge Every Month": "Charge Every Month",
      ThisYear: "This Year",
      Year: "Year",
      Revision: "Revision",
      "Vendor No.": "Vendor No.",
      "Vendor Name": "Vendor Name",
      "Vendor Category": "Vendor Category",
      "WHT. Category": "WHT. Category",
      "WHT. Name": "WHT. Name",
      "Chq. Name": "Chq. Name",
      Fax: "Fax",
      Reference: "Reference",
      "Mapping Ref.": "Mapping Ref.",
      "Branch No.": "Branch No.",
      "Bus Reg No.": "Bus Reg No.",
      "Discount Term": "Discount Term",
      "Less (%)": "Less (%)",
      "Vat Type 1": "Vat Type 1",
      "Vat Rate 1 (%)": "Vat Rate 1 (%)",
      "Dept. Dr Vat 1": "Dept. Dr. Vat 1",
      "Acc. Dr Vat 1": "Acc. Dr. Vat 1",
      "Vat Type 2": "Vat Type 2",
      "Vat Rate 2 (%)": "Vat Rate 2 (%)",
      "Dept. Dr Vat 2": "Dept. Dr. Vat 2",
      "Acc. Dr Vat 2": "Acc. Dr. Vat 2",
      "Cr A/P Dept.": "Cr. A/P Dept.",
      "Cr A/P Acc.": "Cr. A/P Acc.",
      "Cr. Payment Dept.": "Cr. Payment Dept.",
      "Cr. Payment Acc.": "Cr. Payment Acc.",
      "Withholding Tax": "Withholding Tax",
      "WHT. Code": "WHT. Code",
      "Cr. WHT. Dept.": "Cr. WHT. Dept.",
      "Cr. WHT. Acc.": "Cr. WHT. Acc.",
      "WHT. Account": "WHT. Account",
      "Bank Charge": "Bank Charge",
      "Bank Name": "Bank Name",
      "Acc Name": "Acc Name",
      "Acc No": "Acc No.",
      "Bank Ref": "Bank Ref",
      "Bank Ref 1": "Bank Ref 1",
      "Bank Ref 2": "Bank Ref 2",
      "Bank Ref 3": "Bank Ref 3",
      Width: "Width",
      Seq: "Seq",
      "Invoice No.": "Invoice No.",
      "Rcpt. Ref No.": "Rcpt. Ref No.",
      Vendor: "Vendor",
      "Input Date": "Input Date",
      "Invoice Date": "Invoice Date",
      "Cheque No": "Cheque No.",
      "Cheque Date": "Cheque Date",
      "Due Date": "Due Date",
      FolioDate: "Folio Date",
      "Folio Date": "Folio Date",
      "Folio No.": "Folio No.",
      "Guest No.": "Guest No.",
      "Tax Inv No.": "Tax Invoice No.",
      "Tax Status": "Tax Status",
      "Tax Inv Date": "Tax Invoice Date",
      Period: "Period",
      "Tax Period": "Tax Period",
      "Default Tax Period": "Default Tax Period",
      "Default clearing date": "Default Clearing Date",
      "Payment No.": "Payment No.",
      "Payment Date": "Payment Date",
      "Payment Type": "Payment Type",
      Payee: "Payee",
      "WHT. Service Type": "WHT. Service Type",
      "WHT. Form": "WHT. Form",
      "WHT. No": "WHT. No.",
      "WHT. Desc": "WHT. Desc",
      "WHT. Ref.": "WHT. Ref.",
      "WHT. Rate %": "WHT. Rate %",
      "WHT. Amount": "WHT. Amount",
      "Withholding Tax Date": "Withholding Tax Date",
      "WHT Condition": "WHT Condition",
      Ref: "Ref",
      "Ref No.": "Ref No.",
      Paid: "Paid",
      "Base Paid": "Base Paid",
      Unpaid: "Unpaid",
      "Base Unpaid": "Base Unpaid",
      "Total Unpaid": "Total Unpaid",
      "Total Outstanding": "Total Outstanding",
      "Payment Amount": "Payment Amount",
      "Settlement Base Amount": "Settlement Base Amount",
      "Settlement Amount": "Settlement Amount",
      "Net Amount": "Net Amount",
      "Net Payment": "Net Payment",
      "Payment Account": "Payment Account",
      Tax: "Tax",
      TAX: "TAX",
      "Tax Amount": "Tax Amount",
      "Tax 1": "Tax Amount 1",
      "Tax 2": "Tax Amount 2",
      "Tax Base Amount": "Tax Base Amount",
      "Tax Type": "Tax Type",
      "Tax Rate": "Tax Rate",
      "Tax Rate %": "Tax Rate %",
      "Tax Account Code": "Tax Account Code",
      "Dept. Code": "Dept. Code",
      "Acc. Code": "Acc. Code",
      "Dr Account": "Dr. Account",
      "Dr Dept. Code": "Dr. Dept. Code",
      "Dr Acc. Code": "Dr. Acc. Code",
      "Cr Account": "Cr. Account",
      "Cr Dept. Code": "Cr. Dept. Code",
      "Cr Acc. Code": "Cr. Acc. Code",
      "Tax Dr Dept. Code": "Tax Dr. Dept. Code",
      "Tax Dr Acc. Code": "Tax Dr. Acc. Code",
      "Tax Cr Dept. Code": "Tax Cr. Dept. Code",
      "Tax Cr Acc. Code": "Tax Cr. Acc. Code",
      "Receipt Ref. No.": "Receipt Ref. No.",
      "Receipt Date": "Receipt Date",
      "Pay Ref No.": "Pay Ref No.",
      "Pay Date": "Pay Date",
      "Pay Type": "Pay Type",
      "Asset Name": "Asset Name",
      "Asset No.": "Asset No.",
      "Acquire Date": "Acquire Date",
      BarCode: "BarCode",
      Category: "Category",
      Location: "Location",
      "Serial No.": "Serial No.",
      "Transfer Date": "Transfer Date",
      Specification: "Specification",
      "Asset Life": "Asset Life",
      "Init Accu Depre": "Init Accu Depre",
      "Salvage/Unit": "Salvage/Unit",
      "Total Salvage": "Total Salvage",
      "Amount/Unit": "Amount/Unit",
      "Price/Unit": "Price/Unit",
      Price: "Price",
      Group: "Group",
      Unit: "Unit",
      Qty: "Qty",
      Overwrite: "Overwrite",
      Available: "Available",
      "Settle on": "Settle on",
      "Base Amt.": "Base Amt.",
      "Base Amount": "Base Amount",
      "Net Book Value": "Net Book Value",
      "Total Value": "Total Value",
      "Life (Days)": "Life (Days)",
      "Cost Dept.": "Cost Dept.",
      "Cost Acc.": "Cost Acc.",
      "Asset Dept.": "Asset Dept.",
      "Asset Acc.": "Asset Acc.",
      "Accu Dept.": "Accu Dept.",
      "Accu Acc.": "Accu Acc.",
      Depreciation: "Depreciation",
      "Depre Dept.": "Depre Dept.",
      "Depre Acc.": "Depre Acc.",
      "Accu Depre.": "Accu Depre.",
      LastCost: "Last Cost",
      "Disposal Account": "Disposal Account",
      "Disposal Amount": "Disposal Amount",
      "Disposal Type": "Disposal Type",
      "Sale Amount": "Sale Amount",
      "Impairment Amount": "Impairment Amount",
      "Disposal Date": "Disposal Date",
      "Gain/Loss Amount": "Gain/Loss Amount",
      "Total Asset Value": "Total Asset Value",
      "Asset Department": "Asset Department",
      "Asset Account": "Asset Account",
      "Accu Department": "Accu Department",
      "Accu Account": "Accu Account",
      "Gain/Loss Department": "Gain/Loss Department",
      "Gain/Loss Account": "Gain/Loss Account",
      "Sale Department": "Sale Department",
      "Sale Account": "Sale Account",
      "Impairment Value": "Impairment Value",
      "Impairment Account": "Impairment Account",
      "Cr. Impairment Department": "Cr. Impairment Department",
      "Dr. Impairment Department": "Dr. Impairment Department",
      "Cr. Impairment Account": "Cr. Impairment Account",
      "Dr. Impairment Account": "Dr. Impairment Account",
      "Accu Depre Department": "Accu Depre Department",
      "Accu Depre Account": "Accu Depre Account",
      Actual: "Actual",
      "Variance (%)": "Variance (%)",
      "Last Year": "Last Year",
      "Annual Budget": "Annual Budget",
      "Revision Budget": "Revision Budget",
      "Hotel Name": "Hotel Name",
      "Register Name": "Register Name",
      "Company Register ID": "Company Register ID",
      "Branch ID": "Branch ID",
      "Opening Balance": "Opening Balance",
      "This Month": "This Month",
      "Closing Balance": "Closing Balance",
      Balance: "Balance",
      "Last Closed Period": "Last Closed Period",
      "Current Period": "Current Period",
      "Amount to be allocated": "Amount to be allocated",
      "Allocate Unit": "Allocate Unit",
      "Amortize Amount": "Amortize Amount",
      "Amortized by": "Amortized by",
      "Amount to Amortize": "Amount to Amortize",
      "Base Amount to Amortize": "Base Amount to Amortize",
      "Use In A/P": "Use In A/P",
      "Use In A/R": "Use In A/R",
      "Previous Day": "Previous Day",
      Summary: "Summary",
      Undue: "Undue",
      Overdue: "Overdue",
      Current: "Current",
      MTD: "MTD",
      YTD: "YTD",
      "YTD-Last Year": "YTD-Last Year",
      "YTD Budget": "YTD Budget",
      "Setup Lookup": "Setup Lookup",
      Revenue: "Revenue",
      "Market Segment": "Market Segment",
      "Statistical Data": "Statistical Data",
      "Days In Month": "Days In Month",
    },
    fieldAbbr: {
      select: "Select",
      searchby: "Search by ",
      date: "Date",
      prefix: "Prefix",
      type: "Type",
      jv: "Journal Voucher",
      jvNo: "Journal Voucher No.",
      desc: "Description",
      vouNo: "Voucher No.",
      source: "Source",
      status: "Status",
      active: "Active",
      inactive: "Inactive",
      account: "Account",
      account1: "Account #",
      dept: "Department",
      deptName: "Department Name",
      SelectAccountCode: "SelectAccountCode",
      accountCode: "Account Code",
      accountName: "Account Name",
      currency: "Currency",
      rate: "Rate",
      percent: "Percent",
      ratePercent: "Rate (%)",
      nature: "Nature",
      dr: "Debit",
      cr: "Credit",
      address: "Address",
      amount: "Amount",
      base: "Base",
      totalAmount: "Total",
      cheqDate: "Cheq. Date",
      cheqNoFrom: "Cheq. No. From",
      cheqNoTo: "Cheq. No. To",
      clearingDate: "Clearing Date",
      comment: "Comment",
      "Selected Folio": "Selected Folio",
      "Unselected Folio": "Unselected Folio",
    },
    gl: {
      amortizeAmtWarnningZero: "Cannot process, amortize amount is zero",
      allocateAmtWarnningZero: "Cannot process, allocate amount is zero",
      allocateUnitWarnningZero: "Cannot process, allocate unit is zero",
      "Allow edit JV. from posting": "Allow edit JV from posting",
      "Allow edit JV. from posting desc": "Allow to edit Journal Voucher that posted from other source",
      "Reverse Account Nature When Negative": "Reverse Account Nature When Negative",
      "Reverse Account Nature When Negative desc": "Allow entry negative amount in ledger",
      "Reverse Account Nature When Negative info":
        "Inactive : JV posted from other source if negative amount then auto change Dr. > Cr., Cr.<Dr. \nNot allow to save JV if amount is negative",
      "Retain Earning Account": "Retain Earning Account",
      "Gl Posting Account": "Gl Posting Account",
      "Gl Posting Account info":
        "CM4 force department code in transaction and all mapping from other system force to identify department of transaction so this configuration should be removed",
      "Fiscal Year": "Fiscal Year",
      "Closed Period": "Closed Period",
      "Current Period": "Current Period",
    },
    ap: {
      "Aging Period": "Aging Period",
      "Payment Posting": "Payment Posting",
      "Default Tax Invoice Status": "Default Tax Invoice Status",
      "Default Unit": "Default Unit",
      "Allow duplicate Cheque No.": "Allow duplicate Cheque No.",
      "Allow to edit posting invoice": "Allow to edit posting invoice",
      "Allow to delete posting invoice": "Allow to delete posting invoice",
      "Allow re-print form cheque": "Allow re-print form cheque",
      "Allow create duplicate TaxID on vendor profile": "Allow create duplicate TaxID on vendor profile",
      "Enable Thai Tax": "Enable Thai Tax",
      "Auto Posting Vat Reconcile JV": "Auto Posting Vat Reconcile JV",
      "Reverse Pending Tax Invoice": "Reverse Pending Tax Invoice",
      "Add other tax invoices to final Input Tax Report": "Add other tax invoices to final Input Tax Report",
      "Input Tax Reconciliation Completed": "Input Tax Reconciliation Completed",
    },
    apInvoice: {
      title: "Invoice",
      delInvoiceSettled: "Cannot void, this invoice has been settled.",
      settleWarnning:
        "This invoice has been settled, posted from other or it is within closed period. Only allowed to edit Tax Invoice. Do you want to continue?",
      invoiceAmountWarnningZero: "Cannot process, paid is zero.",
      invoiceAmountWarnning1: "Cannot process, invoice amount is less than paid amount.",
      invoiceAmountWarnning2: "Invoice amount is less than paid amount.",
    },
    apPayment: {
      title: "Payment",
      closePeriodWarnning:
        "This payment is within closed period. Only allowed to edit cheque. Do you want to continue?  ",
      chequeWarnning: "Cheque number or cheque date cannot be empty.",
    },
    ar: {
      "Default Value (A/R Invoice)": "Default Value (A/R Invoice)",
      "(Debit) Account Code": "(Debit) Account Code",
      "(Credit) Account Code": "(Credit) Account Code",
      "Default Value (A/R Receipt)": "Default Value (A/R Receipt)",
      "Copy Receipt Ref No. to Tax Invoice": "Copy Receipt Ref No. to Tax Invoice",
      "Allow Edit Folio In (A/R Invoice)": "Allow Edit Folio In (A/R Invoice)",
      amountWarnningZero: "Cannot process, amount is zero.",
      receiptAmountWarnning1: "Cannot process, payment amount is less than settlement amount.",
      folioAmountWarnning1: "Cannot process, paid is more than unpaid.",
      "Invoice - Tax Invoice Prefix": "Invoice - Tax Invoice Prefix",
    },
    asset: {
      disposed: "This asset has already been disposed.",
      "Asset Register Number Format": "Asset Register Number Format",
      "Enable Asset Barcode": "Enable Asset Barcode",
      "Reverse Assets and Acc. Depre. Account": "Reverse Assets and Acc. Depre. Account",
      "Set Asset Account Code for Posting": "Set Asset Account Code for Posting",
    },
    closePeriod: {
      warning: "Cannot %{action} this %{name} it is within closed period.",
      dateWarning: "%{name} must be later than closed period date.",
      setclearing: "Set clearing date by bank statement file",
    },
    permission: {
      denied: "Access Denied",
      selectVendorFirst: "Please select vendor first!",
      selectProfileFirst: "Please select AR profile first!",
      selectWHTAccount: "Please select WHT. Account",
      selectGainLossAccount: "Please select Gain/Loss Account",
    },
    validation: {
      required: "* Required",
      minLength: "Must be %{min} characters at least",
      maxLength: "Must be %{max} characters or less",
      minValue: "Must be at least %{min}",
      maxValue: "Must be %{max} or less",
      number: "Must be a number",
    },
    isEmpty: "%{code} is empty",
  },
};

export default enCustomMessages;
